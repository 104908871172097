import { batch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  HIDE_ALL_RELATIONSHIPS,
  SHOW_ALL_RELATIONSHIPS,
  TLP,
} from "../../../../constants";
import api from "../../../../constants/api";
import {
  addApiRequestId,
  addProcessingEntities,
  hideAllRelationships,
  showAllRelationships,
} from "../../../../store/actions";
import { removeManyRelationshipsRequest } from "../../../../store/actions/apiRequests";
import {
  appendHiddenLineStyle,
  removeHiddenLineStyle,
} from "../../../../utils/helpers/analysis/relationship";
import infoMessages from "../../../../utils/messages/infoMessages";
import { showModalConfirm } from "../../../common/widgets/WarningDialog";
import axios from "axios";
import { browserDownload } from "../../../../utils/helpers";
import Mixpanel from "../../../../utils/services/Mixpanel";
import events from "../../../../constants/analyticEvents";
import messageBox from "../../../../utils/services/MessageBox";

// Toggle visibility of all relationships
export const relationshipVisibility = (visibility, dispatch) => {
  if (visibility === SHOW_ALL_RELATIONSHIPS) {
    removeHiddenLineStyle();
    dispatch(showAllRelationships());
  } else if (visibility === HIDE_ALL_RELATIONSHIPS) {
    appendHiddenLineStyle();
    dispatch(hideAllRelationships());
  }
};

export const deleteAllRelationship = (
  documentId,
  { relations, entityRelations },
  dispatch
) => {
  showModalConfirm({
    title: infoMessages.delete_all_relationships_warning,
    content: <em>{infoMessages.is_this_what_you_want}</em>,
    onOk: () => {
      const relationshipIds = relations.map((r) => r.id);
      const entityIds = Object.keys(entityRelations);
      const requestId = uuidv4();

      batch(() => {
        dispatch(
          removeManyRelationshipsRequest(documentId, relationshipIds, requestId)
        );
        dispatch(addProcessingEntities(entityRelations));
        dispatch(addApiRequestId({ [requestId]: entityIds }));
      });
      Mixpanel.track(events.DELETE_ALL_RELATIONSHIPS, { documentId });
    },
  });
};

export const handleEnableEdit = (title, titleInputRef, setTitleText) => (e) => {
  e.stopPropagation();
  setTitleText(title);
  if (title) {
    process.nextTick(() => {
      titleInputRef.current.focus({
        cursor: "all",
      });
    });
  }
};

export const handleKeyPress = (setTitleText) => (e) => {
  if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
    setTitleText(null);
  }
};

export const handleSaveTitle =
  (documentId, titleText, title, setTitleText, updateRequest) => (e) => {
    if (!titleText) return messageBox.showError("Document title is required!");
    if (titleText === title) return setTitleText(null);
    updateRequest(`${api.DOCUMENT_API}/${documentId}`, { title: titleText });
  };

export const handleGenerateStix = (documentId, generateStixRequest) => {
  generateStixRequest(
    api.GENERATE_STIX_BUNDLE.replace(":documentId", documentId)
  );
};

export const handleDownloadOptions = (
  apiUrl,
  filename,
  token,
  setDownloading
) => {
  return () => {
    setDownloading(true);
    axios
      .get(apiUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        browserDownload(url, filename);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        messageBox.showError("Unable to download file");
      });
  };
};

export const tlpStyle = (tlpLevel) => {
  let color =
    tlpLevel === TLP.AMBER || tlpLevel === TLP.AMBER_STRICT
      ? "#FFBF00"
      : tlpLevel;
  color = color === TLP.WHITE ? undefined : color;
  return { color, fontWeight: "bold" };
};
