import { useEffect, useState } from "react";
import { Checkbox, List, Typography } from "antd";

const { Title } = Typography;

export const OptionList = ({
  title,
  options,
  singleSelect = false,
  noEmptySelection = false,
  defaultSingle,
  footer,
  minWidth,
  onSelection = () => {},
}) => {
  const [value, setValue] = useState();
  const [values, setValues] = useState(new Set());
  const [hasSelected, setHasSelected] = useState(false);

  const handleChange = (value) => () => {
    const v = new Set(values);
    if (v.has(value)) {
      if (noEmptySelection && v.size <= 1) return;
      v.delete(value);
    } else {
      v.add(value);
    }
    setValues(v);
    setHasSelected(true);
  };

  useEffect(() => {
    if (singleSelect) setValue(defaultSingle);
    else {
      setValues(new Set(options.map(({ value }) => value)));
    }
  }, []);

  useEffect(() => {
    onSelection(Array.from(values));
  }, [values]);

  useEffect(() => {
    onSelection(value);
  }, [value]);
  const allChecked =
    options.length > 0 && options.every((option) => values.has(option.value));

  const toggleAll = () => {
    if (allChecked) {
      setValues(new Set());
      setHasSelected(false);
    } else {
      setValues(new Set(options.map(({ value }) => value)));
      setHasSelected(true);
    }
  };
  return (
    <>
      <div className="filterbar-optionlist">
        {!singleSelect && (
          <List
            className="filterbar-optionlist-header"
            size="small"
            header={
              <>
                <List.Item
                  className="filterbar-optionlist-options"
                  type="text"
                  onClick={toggleAll}
                >
                  <Checkbox
                    level={5}
                    type="secondary"
                    underline
                    className="confidence-score-checkbox"
                    checked={allChecked}
                  >
                    All
                  </Checkbox>
                </List.Item>
              </>
            }
            dataSource={options}
            renderItem={(item) => (
              <List.Item className="dropdown-options">
                <Checkbox
                  className="confidence-score-checkbox"
                  onChange={handleChange(item.value)}
                  checked={values.has(item.value)}
                >
                  {item.label}
                </Checkbox>
              </List.Item>
            )}
          />
        )}
        {singleSelect && (
          <List
            className="filterbar-optionlist-header"
            size="small"
            dataSource={options}
            renderItem={(item) => (
              <List.Item
                className={"dropdown-options selected-option"}
                onClick={() => {
                  setValue(item.value);
                  setHasSelected(true);
                }}
              >
                {item.label}
              </List.Item>
            )}
          />
        )}
        {hasSelected && footer && (
          <div className="ant-list-footer">{footer}</div>
        )}
      </div>
    </>
  );
};
export default OptionList;
