import { useState, useRef, useEffect } from "react";
import { Input, List, Tooltip } from "antd";
import { ReactComponent as Search } from "../../../assets/svgs/entities/search.svg";
import DisplayTip from "../widgets/DisplayTip";

/**
 * Custom search bar with options listed below
 *
 * @function
 * @param {{array}} options - List of objects with value and label keys/values
 * @param {{function}} onChange - Function to trigger when an option is selected
 * @param {{object}} props - Optional value for customizing input and list
 * @returns React Virtual DOM
 */
const SearchBarWithOptions = ({ options, onChange, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);

  const handleSearch = (e) => {
    setOpen(true);
    setSearchTerm(e.target.value);
  };

  const truncateLabel = (label, maxLength = 20) => {
    return label?.length > maxLength
      ? `${label.slice(0, maxLength)}...`
      : label;
  };

  const filteredOptions = options.filter(
    ({ label, value }) =>
      label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      value?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = async (selectedOption) => {
    if (onChange) {
      await onChange(selectedOption);
      setOpen(false);
    }
  };

  return (
    <div className="searchInput">
      <Input
        onChange={handleSearch}
        value={searchTerm}
        prefix={
          <Search
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          />
        }
        className="searchInput-input"
        onFocus={() => setOpen(true)}
        onClick={(e) => {
          setOpen(true);
        }}
        {...props}
      />
      {open && (
        <List
          dataSource={filteredOptions}
          renderItem={({ label, value, description }) => (
            <List.Item
              onClick={() => handleOptionClick(value)}
              className="hover-list-item"
            >
              <div className="hover-list-item-value">
                <DisplayTip
                  title={
                    <>
                      <div className="hover-list-item-value-title">{label}</div>
                      {truncateLabel(description, 240)}
                    </>
                  }
                  overlayClassName="list-item-value-title-tooltip"
                  placement="bottom"
                >
                  {truncateLabel(value, 25)}
                </DisplayTip>
              </div>
              <div className="">
                <DisplayTip
                  title={label}
                  overlayClassName="label-tooltip"
                  placement="bottom"
                >
                  {truncateLabel(label)}
                </DisplayTip>
              </div>
            </List.Item>
          )}
          id="list"
          className="list"
        />
      )}
    </div>
  );
};

export default SearchBarWithOptions;
