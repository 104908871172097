import React, { useState } from "react";
import { Table, Form, Button, Dropdown, Menu } from "antd";
import EditableCell from "./EditableCell";
import { ReactComponent as Morehorizontal } from "../../../assets/svgs/entities/morehorizontal.svg";
import { Pagination } from "../../pages/rulesManager/Pagination";
import ActionTip from "../widgets/ActionTip";
import WarningDialog from "../widgets/WarningDialog";
import {
  deleteOrganisationMetadataKey,
  deleteOrganisationMetadataProfile,
} from "../../../store/actions/metadata";
import { useDispatch } from "react-redux";
import { METADATA_KEYS } from "../../../constants";

const EditableTable = ({
  columns = [],
  data,
  saveEditHandler,
  editErrorHandler,
  editHandler,
  addActionNodes = null,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const dispatch = useDispatch();
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    const editingFields = {};
    columns.forEach((col) => {
      if (col.editable) editingFields[col.key] = record[col.key];
    });
    form.setFieldsValue(editingFields);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const fields = await form.validateFields();
      setEditingKey("");
      if (saveEditHandler) saveEditHandler(fields, record);
    } catch (errInfo) {
      if (editErrorHandler) editErrorHandler(errInfo);
    }
  };
  const [dropdownVisible, setDropDownVisible] = useState(null);

  const closeDropdown = () => {
    setDropDownVisible(false);
  };

  const renderActionsMenu = (record, act) => {
    const deleteRequest =
      activeTab === METADATA_KEYS
        ? deleteOrganisationMetadataKey
        : deleteOrganisationMetadataProfile;
    return (
      <Menu className="menu-dropdown-head" style={{ padding: 0, width: 104 }}>
        <Menu.Item
          key="edit"
          onClick={() => {
            closeDropdown();
            if (editHandler) {
              editHandler(record);
            } else {
              edit(record);
            }
          }}
          className="menu-item-rules"
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="delete"
          className="menu-item-rules"
          onClick={closeDropdown}
        >
          <WarningDialog
            title="Delete this record?"
            onConfirm={() => {
              dispatch(deleteRequest(record.key));
            }}
            text="textConfirmation"
          >
            <div className="menu-item-dropdown-delete">Delete</div>
          </WarningDialog>
        </Menu.Item>
      </Menu>
    );
  };

  const actionColumn = {
    title: "",
    dataIndex: "action",
    align: "right",
    width: "303px",
    className: "title-column-more-actions-icon",
    render: (_, record) => {
      const editable = isEditing(record);

      return editable ? (
        <span>
          <Button onClick={() => save(record)} className="px-5" type="link">
            Save
          </Button>
          <Button onClick={cancel} className="px-5" type="link">
            Cancel
          </Button>
        </span>
      ) : (
        <>
          <Dropdown
            overlay={renderActionsMenu(record)}
            trigger={["click"]}
            visible={dropdownVisible === record.key}
            onVisibleChange={(visible) =>
              setDropDownVisible(visible ? record.key : null)
            }
          >
            <ActionTip title="More actions" className="more-actions-rules-page">
              <Morehorizontal className="more-actions-icon" />
            </ActionTip>
          </Dropdown>
        </>
      );
    },
  };

  const cols = [...columns, actionColumn];

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        required: !!col.required,
        editing: isEditing(record),
      }),
    };
  });

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "table-even-row" : "table-odd-row";
  };

  const [pagination, setPagination] = useState({
    page: 1,
    length: 10,
    per_page: 10,
  });

  const handlePaginationChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      page,
      per_page: pageSize,
    }));
  };

  return (
    <Form form={form} component={false}>
      <div className="left-sidebar-options-panel-mainBody mt-30">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data.slice(
            (pagination.page - 1) * pagination.per_page,
            pagination.page * pagination.per_page
          )}
          columns={mergedColumns}
          rowClassName={rowClassName}
          className="buckets-table-collection"
          pagination={false}
        />
        {data.length !== 0 && (
          <Pagination
            current={pagination.page}
            total={Math.ceil(data.length / pagination.per_page)}
            pageSize={pagination.per_page}
            handlepagniation={handlePaginationChange}
          />
        )}
      </div>
    </Form>
  );
};

export default EditableTable;
